import * as React from "react";
import type { SVGProps } from "react";
const SvgInstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      fill="currentcolor"
      d="M12 2.178c3.205 0 3.584.012 4.85.07 1.17.053 1.805.248 2.228.413.56.217.96.478 1.38.897s.68.82.897 1.38c.165.423.36 1.059.413 2.228.058 1.266.07 1.645.07 4.85s-.012 3.583-.07 4.849c-.053 1.17-.248 1.805-.413 2.228-.217.56-.477.96-.897 1.38s-.82.68-1.38.898c-.423.164-1.058.36-2.228.413-1.266.057-1.645.07-4.85.07s-3.584-.013-4.849-.07c-1.17-.054-1.805-.25-2.228-.413a3.7 3.7 0 0 1-1.38-.898c-.42-.42-.68-.82-.897-1.38-.165-.423-.36-1.058-.413-2.228-.058-1.265-.07-1.645-.07-4.85s.012-3.583.07-4.849c.053-1.17.248-1.805.413-2.228.217-.56.477-.96.897-1.38s.82-.68 1.38-.897c.423-.165 1.058-.36 2.228-.413 1.265-.058 1.645-.07 4.85-.07m0-2.162c-3.259 0-3.667.013-4.947.072-1.277.058-2.15.261-2.913.558A5.9 5.9 0 0 0 2.014 2.03 5.9 5.9 0 0 0 .63 4.156C.333 4.919.13 5.79.072 7.068.014 8.348 0 8.757 0 12.016s.014 3.668.072 4.948c.058 1.277.261 2.15.558 2.913.307.789.717 1.458 1.384 2.125a5.9 5.9 0 0 0 2.126 1.384c.763.297 1.636.5 2.913.558 1.28.059 1.688.073 4.948.073 3.259 0 3.667-.014 4.947-.073 1.277-.058 2.15-.26 2.913-.558a5.9 5.9 0 0 0 2.125-1.384 5.9 5.9 0 0 0 1.385-2.125c.297-.763.5-1.636.558-2.913.058-1.28.072-1.689.072-4.948s-.014-3.668-.073-4.948c-.058-1.277-.26-2.15-.557-2.912a5.9 5.9 0 0 0-1.384-2.126A5.9 5.9 0 0 0 19.86.646c-.763-.297-1.636-.5-2.913-.558-1.28-.059-1.688-.072-4.947-.072"
    />
    <path
      fill="currentcolor"
      d="M12.002 5.855a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324m0 10.163a4 4 0 1 1 0-8 4 4 0 0 1 0 8M18.405 7.048a1.44 1.44 0 1 0 0-2.88 1.44 1.44 0 0 0 0 2.88"
    />
  </svg>
);
export default SvgInstagramIcon;
