import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgLogo from '../generated/svg/logo'
import SvgInstagramIcon from '../generated/svg/instagram-icon'
import SvgXIcon from '../generated/svg/x-icon'
import SvgBag from '../generated/svg/bag'
import SvgPerson from '../generated/svg/person'
import { pageMaxWidth } from '../styles/page-max-width'

const Outer = styled.div`
  padding-top: 5rem;
  padding-bottom: 5rem;
  color: white;
  background-color: #2F2F2F;
`

const Inner = styled.div`
  ${pageMaxWidth}
  display: grid;
  grid-template-columns: repeat(3, auto);
  @media (max-width: 40rem){
    align-items: center;
    gap: 2rem;
  }
`

const Logo = styled.div`
  grid-area: 1 / 1;
  svg {
    display: block;
    height: 3rem;
    width: auto;
    @media (max-width: 40rem){
      height: 1.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
`

const SnsIconContainer = styled.div`
  display: flex;
  gap: 1.875rem;
  grid-area: 2 / 1;
  @media (max-width: 40rem){
    gap: 1.125rem;
    grid-area: 3 / 1 / auto / span 2;
  }
`

const SnsIconItem = styled.a`
  display: block;
  svg {
    display: block;
    height: 1.625rem;
    width: auto;
    @media (max-width: 40rem){
      height: 1.125rem;
      padding: 1rem;
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }
`

const Navigate1Container = styled.div`
  display: flex;
  gap: 2rem;
  grid-area: 1 / 2 / span 2 / span 2;
  justify-self: end;
  margin-top: -0.5rem;
  margin-bottom: 6rem;
  @media (max-width: 40rem){
    row-gap: 1.875rem;
    grid-area: 2 / 1 / auto / span 2;
    grid-template-columns: repeat(2, auto);
    grid-auto-rows: auto;
    justify-self: start;
    margin-bottom: 0;
  }
`

const Navigate1Inner = styled.div`
`

const Navigate1List = styled.div`
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  @media (max-width: 40rem){
    margin-top: 1.25rem;
    gap: 0.9375rem;
  }
`

const Navigate1Item = styled.div`
  padding: 0.5rem 0;
  &:has(${Navigate1List}) {
    grid-column: 2;
  }
`

const Navigate1Label = styled.a`
  display: block;
  font-size: 0.8125rem;
  @media (max-width: 40rem){
    font-size: 0.75rem;
  }
`

const Navigate1ListItem = styled.a`
  display: block;
  font-size: 0.8125rem;
  color: #BEBEBE;
  @media (max-width: 40rem){
    font-size: 0.6875rem;
  }
  &::before {
    content: '・';
  }
`

const Navigate2Container = styled.div`
  display: flex;
  align-self: center;
  margin-left: -0.75rem;
  grid-area: 3 / 1 / auto / span 2;
  @media (max-width: 40rem){
    grid-area: 4 / 1 / auto / span 2;
    flex-wrap: wrap;
    row-gap: 1rem;
  }
`

const Navigate2Item = styled.a`
  display: block;
  font-size: 0.875rem;
  padding: 0 0.75rem;
  @media (max-width: 40rem){
    font-size: 0.6875rem;
  }
  &:not(:last-child) {
    border-right: 1px solid currentColor;
  }
`

const IconContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: flex-end;
  @media (max-width: 40rem){
    gap: 1.125rem;
    grid-area: 1 / 2;
  }
`

const IconItem = styled.a`
  display: block;
  svg {
    display: block;
    height: 2.125rem;
    width: auto;
    @media (max-width: 40rem){
      height: 1.125rem;
    }
  }
`

const Copyright = styled.div`
  font-size: 0.875rem;
  margin-top: 1.5rem;
  grid-area: 4 / 1 / auto / span 3;
  @media (max-width: 40rem){
    font-size: 0.6875rem;
    grid-area: 5 / 1 / auto / span 2;
  }
`

type FooterProps = {

}
export const Footer: FunctionComponent<FooterProps> = () => {
  return <Outer>
    <Inner>
      <Logo>
        <SvgLogo />
      </Logo>
      <SnsIconContainer>
        <SnsIconItem href="https://www.instagram.com/apros_life_store/" target="_blank">
          <SvgInstagramIcon />
        </SnsIconItem>
        {/* <SnsIconItem>
          <SvgXIcon />
        </SnsIconItem> */}
      </SnsIconContainer>
      <Navigate1Container>
        <Navigate1Inner>
          <Navigate1Item>
            <Navigate1Label href="/shop/information_categories/topics">
              TOPICS
            </Navigate1Label>
          </Navigate1Item>
          <Navigate1Item>
            <Navigate1Label href="/shop/information_categories/news">
              INFORMATION
            </Navigate1Label>
          </Navigate1Item>
          <Navigate1Item>
            <Navigate1Label href="/shop/faq">
              FAQ
            </Navigate1Label>
          </Navigate1Item>
          <Navigate1Item>
            <Navigate1Label href="/shop/contact">
              CONTACT
            </Navigate1Label>
          </Navigate1Item>
        </Navigate1Inner>
        <Navigate1Inner>
          <Navigate1Item>
            <Navigate1Label>
              PRODUCTS
            </Navigate1Label>
            <Navigate1List>
              <Navigate1ListItem href="/shop/products/ZC001">
                APROS iO
              </Navigate1ListItem>
              <Navigate1ListItem href="/shop/products/ZZ001">
                ダニとり酵母
              </Navigate1ListItem>
              <Navigate1ListItem href="/shop/products/ZH001">
                シボサポ
              </Navigate1ListItem>
              <Navigate1ListItem href="/shop/products/ZH010">
                メグラナイト
              </Navigate1ListItem>
            </Navigate1List>
          </Navigate1Item>
        </Navigate1Inner>
      </Navigate1Container>
      <Navigate2Container>
        <Navigate2Item href="/shop/base_info">
          当サイトについて
        </Navigate2Item>
        <Navigate2Item href="/shop/customer_term">
          利用規約
        </Navigate2Item>
        <Navigate2Item href="/shop/privacy">
          プライバシーポリシー
        </Navigate2Item>
        <Navigate2Item href="/shop/law_info">
          特定商取引法に基づく表記
        </Navigate2Item>
        <Navigate2Item href="/shop/guide">
          ご利用ガイド
        </Navigate2Item>
      </Navigate2Container>
      <IconContainer>
        <IconItem href="/shop/cart">
          <SvgBag />
        </IconItem>
        <IconItem href="/shop/customer">
          <SvgPerson />
        </IconItem>
      </IconContainer>
      <Copyright>
        ©Apros life store All rights reserved
      </Copyright>
    </Inner>
  </Outer>
}